* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  background-color: #2b2b2b;
}

.App-header {
  border-bottom: solid 2px #70c748;
  box-shadow: 0px 8px 20px 0px #70c748;
}

.headerMobile {
  display: none;
}

.nav {
  background-color: #161616;
  color: #5b8a3c;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 2rem;
  font-size: 1.5rem;
  text-shadow: 0px 0px 10px green;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1.25rem;
}

#myLinks li {
  list-style: none;
}

.site-title {
  font-size: 3rem;
  text-shadow: 0px 0px 15px green;
}

.nav li.active {
  background-color: #383838;
  box-shadow: 0px 8px 20px 0px #70c748;
  list-style: none;
}

.nav li:hover {
  background-color: #bebebe;
}

main {
  justify-content: center;
  display: flex;
  align-items: baseline;
  background-color: #383838;
}

.MainContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: #1d1d1d;
  justify-content: center;
  align-items: center;
  color: #5b8a3c;
  text-shadow: 0px 0px 2px green;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

#HeroImg {
  width: 30%;
  height: 100%;
  border-radius: 50%;
  margin: auto;
  margin-top: 6rem;
  box-shadow: 0px 0px 25px 5px #70c748;
  animation-name: hover;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  background-color: #161616;
}

@keyframes hover {
  from  {transform: translateY(0px);}
  to {transform: translateY(-25px);}
}

#AboutMe {
  color: green;
}

.btn {
  font-size: 20px;
  border-radius: 4px;
  width: fit-content;
  color: #49eb49;
  border: solid #7cd64f 2px;
  background-color: #404040;
  text-decoration: none;
  padding: 5px 10px;
  box-shadow: 0px 8px 20px 0px #70c748;
  text-shadow: 0px 0px 5px #5b8a3c;
  transition: transform .2s;
  cursor: pointer;
}

.btn a {
  text-decoration: none;
  color: #49eb49;
}

.btn:hover {
  transform: scale(1.5);
  color: black;
}

.contentBlock {
  display: flex;
  justify-content: center;
}

.textBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #161616;
  margin: 25px;
  border: solid 2px #49eb49;
  box-shadow: 0px 0px 40px 0px #70c748;
}

.About {
  width: 63%;
}

.txt {
  width: 90%;
}

.txtBottum {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.footerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  padding: 2%;
  width: 100%;
  background-color: #161616;
  border-top: solid 2px #70c748;
}

.contentBlock input, textarea {
  background-color: #383838;
  color: #49eb49;
}

.contentBlock form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border: solid 2px #49eb49;
  box-shadow: 0px 0px 40px 0px #70c748;
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .headerMobile {
    display: block;
  }

  .header {
    display: none;
  }

  .site-title {
    font-size: 1.75rem;
  }

  .contentBlock {
    flex-direction: column-reverse;
  }

  #HeroImg {
    width: 75%;
    margin-top: 1rem;
  }

  .About {
    width: auto;
  }

  .footerBox {
    gap: 2rem;
  }
}
/* TODO mobile css */